const TemaDefault = (revenda) => {
  let colors = {};

  switch (revenda) {
    case "AZUL":
      colors = {
        descricao: "Azul",
        primary: "#022d99",
        corSecundaria: "#0141e1",
        corTerciaria: "#021d62",
        corTexto: "#FFFFFF",
        corBotao: "#0338be",
      };
      break;

    case "AZUL2":
      colors = {
        descricao: "Azul Claro",
        primary: "#053063",
        corSecundaria: "#053063",
        corTexto: "#FFFFFF",
      };

      break;

    case "AZUL3":
      colors = {
        descricao: "Azul Escuro",
        primary: "#053063",
        corSecundaria: "#053063",
        corTexto: "#FFFFFF",
      };
      break;

    case "AZUL4":
      colors = {
        descricao: "Azul Marinho",
        primary: "#053063",
        corSecundaria: "#1c2137",
        corTerciaria: "#1c2137",
        corBotao: "#053063",
        corTexto: "#FFFFFF",
      };
      break;
    case "VINHO":
      colors = {
        descricao: "Vinho",
        primary: "#A70230",
        corSecundaria: "#f47d83",
        corTerciaria: "#860127",
        corBotao: "#e80242",
        corTexto: "#FFFFFF",
      };
      break;

    case "VINHO2":
      colors = {
        descricao: "Vinho Escuro",
        primary: "#A70230",
        corSecundaria: "#f47d83",
        corTexto: "#FFFFFF",
      };
      break;

    case "TOYOTA":
      colors = {
        descricao: "Vermelho",
        primary: "#f4181b",
        corSecundaria: "#d00004",
        corTerciaria: "#ff4063",
        corTexto: "#ffffff",
      };
      break;
    case "CINZA":
      colors = {
        descricao: "Cinza",
        primary: "#053063",
        corSecundaria: "#053063",
        corTexto: "#FFFFFF",
      };
      break;
    case "AMARELO":
      colors = {
        descricao: "Amarelo",
        primary: "#fcb415",
        corSecundaria: "#f2e655",
        corTerciaria: "#d09718",
        corBotao: "#f4b62d",
        corTexto: "#FFFFFF",
      };
      break;

    case "VERDE":
      colors = {
        descricao: "VERDE",
        primary: "#0a7238",
        corSecundaria: "#178f4c",
        corTerciaria: "#004820",
        corBotao: "#178849",
        corTexto: "#FFFFFF",
      };
      break;
    case "VERDE2":
      colors = {
        descricao: "Verde Escuro",
        primary: "#0a7238",
        corSecundaria: "#178f4c",
        corTerciaria: "#004820",
        corBotao: "#178849",
        corTexto: "#FFFFFF",
      };
      break;
    case "PRATA":
      colors = {
        descricao: "Prata",
        primary: "#d8d8d8",
        corSecundaria: "#898585",
        corTexto: "#FFFFFF",
        corBotao: "#8d8b8b",
      };
      break;

    case "PRETO":
      colors = {
        descricao: "Preto",
        primary: "#000000",
        corSecundaria: "#000000",
        corTerciaria: "#000000",
        corTexto: "#FFFFFF",
        corBotao: "#000000",
      };
      break;

    default:
      colors = {
        descricao: "Padrão",
        primary: "#333",
        corSecundaria: "#898585",
        corTexto: "#FFFFFF",
        corBotao: "#8d8b8b",
      };
      break;
  }
  return colors;
};

export default TemaDefault;
