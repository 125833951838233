import { useEffect, useState } from "react";
import { createContext } from "use-context-selector";
import { useImmer } from "use-immer";
import useStore from "../hooks/useStore";
import useWebService from "./../hooks/useWebService";
export const OrcComplementarContext = createContext(null);

export default function OrcComplementarProvider({ children }) {
  const { WebService } = useWebService();
  const { setChave, reset, chave: prevChave,formPgtoSelecionada,setFinalizouOrc,setTipoAcesso } = useStore();
  const [isLoading, setLoading] = useState("idle");
  const [erro, setErro] = useState(null);
  const [orcamento, setOrcamento] = useImmer({});

  useEffect(() => {
    if (orcamento == null) {
      let tempData = window.sessionStorage.getItem("orc-compl-storage");
      if (tempData != null && tempData !== undefined) {
        console.log("ATENDIMENTO SESSION STORAGE", { tempData });
        setOrcamento(JSON.parse(tempData));
      }
    }
  }, [orcamento, setOrcamento]);

  const getOrcamento = async (chave,tipoAcesso, history) => {
    setLoading("loading");
    if (chave !== prevChave) reset();
    try {
      const urlBusca = tipoAcesso ? `buscaOrcamentoOrcComplementar?chave=${chave}&tipoAcesso=${tipoAcesso}` : `buscaOrcamentoOrcComplementar?chave=${chave}`
      const response = await WebService.get(
        urlBusca
      );
      if (!response.data.statusRetorno.sucesso) {
        throw new Error(response.data.statusRetorno.mensagemErro);
      }
      setOrcamento(response.data);
      setChave(chave);
      setTipoAcesso(tipoAcesso);
      setLoading("idle");
      window.sessionStorage.setItem(
        "orc-compl-storage",
        JSON.stringify(response.data)
      );
    } catch (error) {
      setChave(null);
      history.push("/key-error");
      setLoading("error");
      setErro(error);
    }
  };

  const setOrcamentoFinalizado = () => {
    const formPgto = null
   if(orcamento.formasPgto){
    formPgto = orcamento.formasPgto.find(x => x.idFormaPag == formPgtoSelecionada);
  }
    setOrcamento({
      ...orcamento,
      orcamentoFinalizado: true,
      formPgtoAnterior: formPgto ? formPgto.descFormaPag : undefined
    });
    setFinalizouOrc(true);

  };

  return (
    <OrcComplementarContext.Provider
      value={{
        isLoading,
        erro,
        orcamento,
        getOrcamento,
        setOrcamentoFinalizado,
      }}
    >
      {children}
    </OrcComplementarContext.Provider>
  );
}
