import React from "react";

import { Container } from "./styles";
import ReactLoading from "react-loading";
function Loading() {
  return (
    <Container>
      <ReactLoading type="spin" className="icon-loading" color={"#333"} />
    </Container>
  );
}

export default Loading;
