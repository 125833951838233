import { useState } from "react";
import { createContext } from "use-context-selector";
import axios from "axios";
import { useEffect } from "react";

export const WebServiceContext = createContext(null);

export default function WebServiceProvider({ children }) {
  const [url, setUrl] = useState(
    "https://solucoes.mobato.com.br/rest/webservice/"
  );

  const changeUrl = (newUrl) => {
    console.warn("LINK WS ATIVO ->", newUrl);
    setUrl(newUrl);
    window.localStorage.setItem("orc-compl-link", newUrl);
  };

  useEffect(() => {
    let link = window.localStorage.getItem("orc-compl-link");
    if (link !== undefined && link != null) {
      setUrl(link);
    }
  }, []);

  const WebService = axios.create({ baseURL: url });
  return (
    <WebServiceContext.Provider value={{ WebService, url, changeUrl }}>
      {children}
    </WebServiceContext.Provider>
  );
}
