import { useContextSelector } from "use-context-selector";
import { OrcComplementarContext } from "../context/OrcComplementar";

function useOrcamento() {
  const isLoading = useContextSelector(
    OrcComplementarContext,
    (state) => state.isLoading
  );
  const erro = useContextSelector(
    OrcComplementarContext,
    (state) => state.erro
  );

  const getOrcamento = useContextSelector(
    OrcComplementarContext,
    (state) => state.getOrcamento
  );

  const categoriaPsList = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.categoriaPsList
  );


  const atendimentoCL = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.atendimentoCL
  );
  const revenda = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.revenda
  );
  const nroOr = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.nroOr
  );

  const orcamentoFinalizado = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.orcamentoFinalizado
  );

  const setOrcamentoFinalizado = useContextSelector(
    OrcComplementarContext,
    (state) => state.setOrcamentoFinalizado
  );
  const orcamentoCancelado = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.orcamentoCancelado
  );
  const orcamentoVencido = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.orcamentoVencido
  );
  const chaveIncorreta = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.chaveIncorreta
  );
  const orcamentoDataValidade = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.orcamentoDataValidade
  );
  const obsGeral = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.obsGeral
  );
  const obsCliente = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.obsCliente
  );

  const formasPgto = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.formasPgto
  );

  const motivosReprovaList = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.motivosReprovaList
  );

  const orcamentosAprovados = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.orcamentoAprovadoList
  );

  const formPgtoAnterior = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.formPgtoAnterior
  );

  const permiteExibicaoFormPgtoOrcComplementar = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.revenda?.permiteExibicaoFormPgtoOrcComplementar
  );

  const assinaturaObrigatoriaOrcComplementar = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.revenda?.assinaturaObrigatoriaOrcComplementar
  );
  const imagemAssCliente = useContextSelector(
    OrcComplementarContext,
    (state) => state.orcamento?.imagemAssCliente
  );
  return {
    isLoading,
    erro,
    categoriaPsList,
    atendimentoCL,
    revenda,
    nroOr,
    getOrcamento,
    orcamentoFinalizado,
    setOrcamentoFinalizado,
    orcamentoCancelado,
    orcamentoVencido,
    chaveIncorreta,
    orcamentoDataValidade,
    obsGeral,
    obsCliente,
    formasPgto,
    motivosReprovaList,
    formPgtoAnterior,
    orcamentosAprovados,
    permiteExibicaoFormPgtoOrcComplementar,
    assinaturaObrigatoriaOrcComplementar,
    imagemAssCliente
  };
}

export default useOrcamento;
