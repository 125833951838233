import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import produce from "immer";

const persistStore = {
  name: "state",
  getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
};

const store = (set, get) => ({
  aprovados: [],
  reprovados: [],
  obs: [],
  chave: null,
  formPgtoSelecionada: '',
  finalizouOrc: false,
  assinaturaBase64: '',
  tipoAcesso: undefined,
  addAprovado: async (aprovado) =>
    set(
      produce((oldState) => {
        oldState.reprovados = oldState.reprovados.filter(
          (data) => data.idCategoriaPs != aprovado.idCategoriaPs
        );
        oldState.aprovados.push(aprovado);
      })
    ),
  addReprovado: (oldreprovado,motivoReprova, tipoOpcao) =>
    set(
      produce((oldState) => {
        oldState.aprovados = oldState.aprovados.filter(
          (data) => data.idCategoriaPs != oldreprovado.idCategoriaPs
        );
        let reprovado = {
          ...oldreprovado,
          motivoReprova,
          tipoOpcao
      };
        oldState.reprovados.push(reprovado);
      })
    ),
  setObs: (obs) =>
    set(
      produce((oldState) => {
        oldState.obs = obs;
      })
    ),
  setChave: (chave) =>
    set(
      produce((oldState) => {
        oldState.chave = chave;
      })
    ),
  setTipoAcesso: (tipoAcesso) =>
    set(
      produce((oldState) => {
        oldState.tipoAcesso = tipoAcesso;
      })
    ),
  setFinalizouOrc: (chave) =>
    set(
      produce((oldState) => {
        oldState.finalizouOrc = chave;
      })
    ),
  setFormPayment: (formPgtoSelecionada) =>
    set(
      produce((oldState) => {
        oldState.formPgtoSelecionada = formPgtoSelecionada;
      })
    ),
    setAssinaturaBase64: (assinaturaBase64) =>
    set(
      produce((oldState) => {
        oldState.assinaturaBase64 = assinaturaBase64;
      })
    ),
  reset: () =>
    set(
      produce((oldState) => {
        oldState.reprovados = [];
        oldState.aprovados = [];
        oldState.obs = [];
        oldState.chave = null;
        oldState.formPgtoSelecionada = "";
      })
    ),
});

const useStore = create(devtools(persist(store, persistStore)));

export default useStore;
