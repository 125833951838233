import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loading from "../components/atoms/Loading";

import PrivateRoutes from "./PrivateRoutes";
const Home = lazy(() => import("../pages/Home"));
const Acesso = lazy(() => import("../pages/Acesso"));
const NotFound = lazy(() => import("../pages/NotFound"));
const KeyError = lazy(() => import("../pages/KeyError"));
const Admin = lazy(() => import("../pages/Admin"));

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Suspense fallback={<Loading />}>
          {/* PRIMEIRA PAGIAN / INSERIR CHAVE */}
          <PrivateRoutes exact path="/" component={Home} />
          <Route exact path="/acesso/:chave/:tipoAcesso?" component={Acesso} />
          <Route exact path="/error" component={NotFound} />
          <Route exact path="/key-error" component={KeyError} />
          <Route exact path="/admin" component={Admin} />
        </Suspense>
      </Switch>
    </Router>
  );
}
