import React from "react";

import { Route, Redirect } from "react-router-dom";
import useOrcamento from "~/hooks/useOrcamento";
import useStore from "~/hooks/useStore";

function PrivateRoutes({ component: Component, ...rest }) {
  const { orcamentoCancelado, chaveIncorreta, categoriaPsList } =
    useOrcamento();

  const { chave,tipoAcesso } = useStore();
  const redirectUrl = tipoAcesso ? `/acesso/${chave}/${tipoAcesso}` : `/acesso/${chave}`;

  // console.log(orcamentoCancelado);
  // console.log(chaveIncorreta);
  // console.log(categoriaPsList);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          (orcamentoCancelado !== undefined && !orcamentoCancelado) ||
          (chaveIncorreta !== undefined && !chaveIncorreta) ||
          (categoriaPsList !== undefined && categoriaPsList != null)
        ) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

export default PrivateRoutes;
