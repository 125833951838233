import { useContextSelector } from "use-context-selector";
import { WebServiceContext } from "./../context/WebServiceContext";

function useWebService() {
  const WebService = useContextSelector(
    WebServiceContext,
    (state) => state.WebService
  );
  const changeUrl = useContextSelector(
    WebServiceContext,
    (state) => state.changeUrl
  );
  const url = useContextSelector(WebServiceContext, (state) => state.url);
  return { WebService, url, changeUrl };
}

export default useWebService;
